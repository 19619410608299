
/* eslint-disable */
import firebase from 'firebase/compat/app'

export default {
  name: 'EditReferee',
  props: ['id','title'],
  data: () => ({
    dialog: false,
    loading: false,
    snackbar: {
      visible: false,
      text: 'referee-updated'
    },
    referee: {
      id: '',
      name: '',
      gender: 'male',
      mobile: '',
      birthday: null as any
    },
    activePicker: null,
    date: null as any
  }),
  mounted () {
    this.referee = this.$store.state.RefereesModule.data[this.id]
    const t = new Date(1970, 0, 1)
    t.setSeconds((this.referee.birthday as any).seconds)
    this.date = new Date(t).toISOString().substr(0, 10)
  },
  watch: {
    menu (val) {
      val && setTimeout(() => ((this.$refs.picker as any).activePicker) = 'YEAR')
    }
  },
  methods: {
    setDate (date: Date) {
      (this.$refs.dialog as any).save(date)
    },
    save () {
      this.loading = true
      this.referee.birthday = firebase.firestore.Timestamp.fromDate(new Date(this.date))
      this.$store.dispatch('RefereesModule/patch', this.referee).then(() => {
        this.snackbar.visible = true
        this.loading = false
      }).catch(error => {
        console.log('Error', error)
        this.loading = false
      })
    }
  }
}
